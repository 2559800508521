import { useState, useEffect } from 'react';
import { useTheme } from 'next-themes';
import NextLink from 'next/link';
import Share from '@/components/Share';
import Footer from '@/components/Footer';
import { LogoJsonLd } from 'next-seo';
import AdBanner from './AdBanner';

export default function Container({ noads= false, children }) {
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme } = useTheme();

  // After mounting, we have access to the theme
  useEffect(() => setMounted(true), []);

  return (
    <div>
      <LogoJsonLd url="https://cakeiy.com" logo="https://cakeiy.com/logo.png"></LogoJsonLd>
      <nav className="sticky-nav flex justify-between items-center max-w-4xl w-full p-8 my-0  mx-auto bg-white dark:bg-black bg-opacity-60">
        <button
          aria-label="Toggle Dark Mode"
          type="button"
          className="rounded p-1 w-10 h-10"
          onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
        >
          {mounted && (
            <svg id="logo"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 469.333 469.333"
              fill="currentColor"
              stroke="currentColor"
              className="w-10 h-10 text-gray-800 dark:text-gray-200"
            >

              <path d="M234.56,128c23.573,0,42.667-19.093,42.667-42.667c0-8-2.24-15.573-6.08-21.973L234.56,0l-36.587,63.36
        c-3.84,6.4-6.08,13.973-6.08,21.973C191.893,108.907,210.987,128,234.56,128z"/>
              <path d="M362.56,192L362.56,192H255.893v-42.667h-42.667V192H106.56c-35.307,0-64,28.693-64,64v32.853
        c0,23.04,18.773,41.813,41.813,41.813c11.2,0,21.653-4.373,29.547-12.267l45.653-45.547l45.547,45.44
        c15.787,15.787,43.307,15.787,59.093,0l45.653-45.44l45.547,45.44c7.893,7.893,18.347,12.267,29.547,12.267
        c23.04,0,41.813-18.773,41.813-41.813V256C426.56,220.693,397.867,192,362.56,192z"/>
              <path d="M332.587,341.013L332.587,341.013l-22.933-22.933l-23.04,22.933c-27.84,27.84-76.48,27.84-104.32,0L159.36,318.08
        l-23.04,22.933c-13.76,13.973-32.213,21.653-51.947,21.653c-15.467,0-29.867-4.907-41.813-13.12V448
        c0,11.733,9.6,21.333,21.333,21.333h341.333c11.733,0,21.333-9.6,21.333-21.333v-98.453c-11.947,8.213-26.24,13.12-41.813,13.12
        C365.013,362.667,346.56,354.987,332.587,341.013z"/>


            </svg>
          )}
        </button>
        <div>
          <NextLink href="/" className="p-1 sm:p-4 text-gray-900 dark:text-gray-100">
            Home
          </NextLink>
          <NextLink
            href="/cake-with-name"
            className="p-1 sm:p-4 text-gray-900 dark:text-gray-100">
            Cake with Name
          </NextLink>
          {/* <NextLink href="/blog">
            <a className="p-1 sm:p-4 text-gray-900 dark:text-gray-100">Blog</a>
          </NextLink> */}


        </div>
      </nav>
      {!noads && (
        <div className="container containerdvb">
          <div className="row">
            <div className="col-12 blockStRowDvb">
              <AdBanner />
            </div>
          </div>
        </div>
            )}
      <main className="flex flex-col justify-center  px-8">
      
        {children}
        <Share />
        {!noads && (
        <div className="container containerdvb widerThanParent">
          <div className="row">
            <div className="col-12 blockStRowDvb">
              <AdBanner />
            </div>
          </div>
        </div>
           )}
        <Footer />
      </main>
    </div>
  );
}
import React, { useEffect } from "react";

const AdBanner = () => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <ins className="adsbygoogle blockFixWidthchild"
    style={{display:'block'}}
    data-ad-client="ca-pub-9859060073152158"
    data-ad-slot="5047046928"
    data-ad-format="auto"
    data-full-width-responsive="true"></ins>
  );
};

export default AdBanner;